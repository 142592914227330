import React from "react";

import ChacoLogo from "assets/img/brand/chaco/justicia-logo-chaco.png";
import ChacoLogoLight from "assets/img/brand/chaco/justicia-logo-chaco-light.png";
import ChacoFav from "assets/img/brand/chaco/chaco_iso.png";
import DefaultChaco from "assets/img/brand/chaco/defaultChaco.png";

import InterBannerWA from "assets/img/brand/subastas_electronicas/inter_banners/Inter_bannerWA.webp";


export const site = 'chaco';

export const sources = {
    chaco: {
        url: document.location.origin,
        logo: ChacoLogo,
        logolight: ChacoLogoLight,
        ws_path: `${document.location.origin}/wss`.replace('http', 'ws').replace('https', 'wss'),
        name: 'Subastas Judiciales Chaco',
        navbar_class: 'navbar-chaco',
        headroom_class: 'headroom-chaco',
        favicon: ChacoFav,
        theme_color: '#007f01',
        theme_name: 'chaco',
        defaultImg: DefaultChaco,
        interBanner: [{img: InterBannerWA, link: 'https://chat.whatsapp.com/FW2ngM0l3nNBHdL69N2eAo'}],

        title: 'Subastas Judiciales',
        recaptcha_key: '6LfW0yUqAAAAANAZDmsNRYCmDRs9h46gc-t9wsdq',
        gtm_ID: '',
        contact_data: {
            title: <><h5>Secretaría de subastas</h5>
                    <h5>Poder Judicial de la Provincia del Chaco</h5></>,
            lines: [
                <p>
                    <b>Dirección</b>
                </p>,
                <p>
                    <b>Provincia</b>
                </p>,
                <p>
                    <b>Teléfonos:</b>
                </p>,
                <p>&emsp; completar (directo)</p>,
                <p>&emsp; completar (interno xxx)</p>,
                <p>
                    <b>email:</b> completar
                </p>,
            ],
        },
        support_email: 'soportechaco@esubasta.ar'
    },
};